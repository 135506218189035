// @flow

import * as React from "react";

import { Error503Page } from "tabler-react";

function Error503(props) {
  return <Error503Page />;
}

export default Error503;
