import "./estimationForm.css";
import LoadingIcons from "react-loading-icons";
import SiteWrapper from "../SiteWrapper.react";
import { Page, Grid, Card, colors } from "tabler-react";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { firebaseApp } from "../firebase";
import SEO from "../components/SEO";
// Ui elements from material-core documentation at https://mui.com/components/
import {
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Button,
} from "@material-ui/core";
import StyledRadio from "./StylizedRadioButton";

function EstimationForm() {
  //This parse the data from firebase for each estimates the first tag is id of data set (auto generated)
  const [Estimates, setEstimates] = useState([]);
  useEffect(() => {
    let dataRef = firebaseApp.database().ref();
    dataRef
      .child("11b6a9wHYNX32jZzQ_9JQ2GsnOUtpi2QHcSqJ1zj9pJ4")
      .child("Data")
      .on("value", (snapshot) => {
        var Estimate = snapshot.val();
        setEstimates(Estimate);
      });
  }, []);
  //Had to use two variables for coverage and takeuprate as display value needs to be a string
  const [poverty, setPoverty] = useState(null);
  const [coverage, setCoverage] = useState(0);
  const [coverageDis, setCoverageDis] = useState(null);
  const [fixedCharge, setFixedCharge] = useState(true);
  const [water, setWater] = useState(null);
  const [takeUpRate, setTakeUpRate] = useState(0);
  const [takeUpRateDis, setTakeUpRateDis] = useState(null);
  const [timeFrame, setTimeFrame] = useState(1);
  const [submitState, setSubmitState] = useState(0);
  const [displayResult, setDisplayResult] = useState(0);

  //Selection handlers
  const handleSelectionPoverty = (event) => {
    setPoverty(event.target.value);
  };
  const handleSelectionCoverage = (event) => {
    setCoverageDis(event.target.value);
    if (event.target.value === "25%") {
      setCoverage(0.25);
    }
    if (event.target.value === "50%") {
      setCoverage(0.5);
    }
    if (event.target.value === "75%") {
      setCoverage(0.75);
    }
    if (event.target.value === "100%") {
      setCoverage(1);
    }
  };
  const handleSelectionWater = (event) => {
    setWater(event.target.value);
  };
  const handleSelectionTakeUp = (event) => {
    setTakeUpRateDis(event.target.value);
    if (event.target.value === "50%") {
      setTakeUpRate(0.5);
    }
    if (event.target.value === "60%") {
      setTakeUpRate(0.6);
    }
    if (event.target.value === "70%") {
      setTakeUpRate(0.7);
    }
    if (event.target.value === "80%") {
      setTakeUpRate(0.8);
    }
  };
  const handleSelectionTime = (event) => {
    setTimeFrame(event.target.value);
  };
  const handleSelectionFixedCharge = (event) => {
    setFixedCharge(event.target.checked);
  };

  const [Result, setResult] = useState([]);
  const [Population, setPopulation] = useState([]);
  const [AverageBenfit, setAverageBenfit] = useState([]);
  const [AverageCost, setAverageCost] = useState([]);

  const handleSubmit = () => {
    //guarntee no false submissions
    if (
      poverty == null ||
      coverageDis == null ||
      water == null ||
      takeUpRateDis == null
    ) {
      alert("Please select all options");
    } else {
      if (fixedCharge) {
        if (poverty === 100) {
          Population[0] = Estimates[5].Pov_100;
          if (water === 4500) {
            Result[0] =
              ((Estimates[1].Pov_100 + Estimates[4].Pov_100 * coverage) *
                takeUpRate *
                timeFrame) /
              0.85;
          }
          if (water === 6000) {
            Result[0] =
              ((Estimates[1].Pov_100 + Estimates[3].Pov_100 * coverage) *
                takeUpRate *
                timeFrame) /
              0.85;
          }
          if (water === 7500) {
            Result[0] =
              ((Estimates[1].Pov_100 + Estimates[2].Pov_100 * coverage) *
                takeUpRate *
                timeFrame) /
              0.85;
          }
        }
        if (poverty === 138) {
          Population[0] = Estimates[5].Pov_138;
          if (water === 4500) {
            Result[0] =
              ((Estimates[1].Pov_138 + Estimates[4].Pov_138 * coverage) *
                takeUpRate *
                timeFrame) /
              0.85;
          }
          if (water === 6000) {
            Result[0] =
              ((Estimates[1].Pov_138 + Estimates[3].Pov_138 * coverage) *
                takeUpRate *
                timeFrame) /
              0.85;
          }
          if (water === 7500) {
            Result[0] =
              ((Estimates[1].Pov_138 + Estimates[2].Pov_138 * coverage) *
                takeUpRate *
                timeFrame) /
              0.85;
          }
        }
        if (poverty === 200) {
          Population[0] = Estimates[5].Pov_200;
          if (water === 4500) {
            Result[0] =
              ((Estimates[1].Pov_200 + Estimates[4].Pov_200 * coverage) *
                takeUpRate *
                timeFrame) /
              0.85;
          }
          if (water === 6000) {
            Result[0] =
              ((Estimates[1].Pov_200 + Estimates[3].Pov_200 * coverage) *
                takeUpRate *
                timeFrame) /
              0.85;
          }
          if (water === 7500) {
            Result[0] =
              ((Estimates[1].Pov_200 + Estimates[2].Pov_200 * coverage) *
                takeUpRate *
                timeFrame) /
              0.85;
          }
        }
      } else {
        if (poverty === 100) {
          Population[0] = Estimates[5].Pov_100;
          if (water === 4500) {
            Result[0] =
              (Estimates[4].Pov_100 * coverage * takeUpRate * timeFrame) / 0.85;
          }
          if (water === 6000) {
            Result[0] =
              (Estimates[3].Pov_100 * coverage * takeUpRate * timeFrame) / 0.85;
          }
          if (water === 7500) {
            Result[0] =
              (Estimates[2].Pov_100 * coverage * takeUpRate * timeFrame) / 0.85;
          }
        }
        if (poverty === 138) {
          Population[0] = Estimates[5].Pov_138;
          if (water === 4500) {
            Result[0] =
              (Estimates[4].Pov_138 * coverage * takeUpRate * timeFrame) / 0.85;
          }
          if (water === 6000) {
            Result[0] =
              (Estimates[3].Pov_138 * coverage * takeUpRate * timeFrame) / 0.85;
          }
          if (water === 7500) {
            Result[0] =
              (Estimates[2].Pov_138 * coverage * takeUpRate * timeFrame) / 0.85;
          }
        }
        if (poverty === 200) {
          Population[0] = Estimates[5].Pov_200;
          if (water === 4500) {
            Result[0] =
              (Estimates[4].Pov_200 * coverage * takeUpRate * timeFrame) / 0.85;
          }
          if (water === 6000) {
            Result[0] =
              (Estimates[3].Pov_200 * coverage * takeUpRate * timeFrame) / 0.85;
          }
          if (water === 7500) {
            Result[0] =
              (Estimates[2].Pov_200 * coverage * takeUpRate * timeFrame) / 0.85;
          }
        }
      }

      Population[0] = Population[0] * takeUpRate;

      AverageBenfit[0] = ((Result[0] / timeFrame) * 0.85) / Population[0];

      AverageCost[0] = Result[0] / timeFrame / Population[0];
      //These trunc result to desire signifgantic figures
      Result[0] = Result[0] - (Result[0] % 1000000);
      Population[0] = Population[0] - (Population[0] % 1000);
      AverageBenfit[0] = AverageBenfit[0] - (AverageBenfit[0] % 0.01);
      AverageCost[0] = AverageCost[0] - (AverageCost[0] % 0.01);

      setDisplayResult(0);
      setSubmitState(1);
      setTimeout(() => {
        setSubmitState(0);
        setDisplayResult(1);
      }, 2100);
    }
  };

  return (
    <SiteWrapper>
      <div className="topHeader">
        <h2>Calculator for Nationwide Customer Assistance Programs</h2>
      </div>
      <div className="grid-container">
        <div className="grid-child">
          <div className="centerDiv">
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Take up rate for eligible households.
              </FormLabel>
              <RadioGroup
                aria-label="Take up rate"
                name="TakeUpRate"
                value={takeUpRateDis}
                onChange={(event) => handleSelectionTakeUp(event)}
              >
                <FormControlLabel
                  value="50%"
                  control={<StyledRadio />}
                  label="50% of eligible individuals use the program."
                />
                <FormControlLabel
                  value="60%"
                  control={<StyledRadio />}
                  label="60% of eligible individuals use the program."
                />
                <FormControlLabel
                  value="70%"
                  control={<StyledRadio />}
                  label="70% of eligible individuals use the program."
                />
                <FormControlLabel
                  value="80%"
                  control={<StyledRadio />}
                  label="80% of eligible individuals use the program."
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="centerDiv">
            <FormControl className="formControl">
              <InputLabel labelId="essential_water_label">
                Essential Usage Amount
              </InputLabel>
              <Select
                labelId="essential_water_label"
                id="essential_water"
                value={water}
                onChange={(event) => handleSelectionWater(event)}
              >
                <MenuItem value={4500}>
                  4,500 gallons/ 6 ccf is deemed essential usage.
                </MenuItem>
                <MenuItem value={6000}>
                  6,000 gallons/ 8 ccf is deemed essential usage.
                </MenuItem>
                <MenuItem value={7500}>
                  7,500 gallons/ 10 ccf is deemed essential usage.
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="grid-child">
          <div className="centerme">
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Percentage of volumetric charges covered by program
              </FormLabel>
              <RadioGroup
                aria-label="Covered usage"
                name="CoveredUsage"
                value={coverageDis}
                onChange={(event) => handleSelectionCoverage(event)}
              >
                <FormControlLabel
                  value="25%"
                  control={<StyledRadio />}
                  label="25% of charges for essential usage"
                />
                <FormControlLabel
                  value="50%"
                  control={<StyledRadio />}
                  label="50% of charges for essential usage"
                />
                <FormControlLabel
                  value="75%"
                  control={<StyledRadio />}
                  label="75% of charges for essential usage"
                />
                <FormControlLabel
                  value="100%"
                  control={<StyledRadio />}
                  label="100% of charges for essential usage"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="centerDiv">
            <FormControl className="formControl">
              <InputLabel labelId="poverty_label">
                Eligibility requirement
              </InputLabel>
              <Select
                labelId="poverty_label"
                id="poverty"
                value={poverty}
                onChange={(event) => handleSelectionPoverty(event)}
              >
                <MenuItem value={100}>
                  At or below 100% of the federal poverty rate.
                </MenuItem>
                <MenuItem value={138}>
                  At or below 138% of the federal poverty rate.
                </MenuItem>
                <MenuItem value={200}>
                  At or below 200% of the federal poverty rate.
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      <div className="centerDiv">
        <FormControl className="formControl">
          <InputLabel labelId="TimeFrame_label">Timeframe</InputLabel>
          <Select
            labelId="TimeFrame_label"
            id="TimeFrame"
            value={timeFrame}
            onChange={(event) => handleSelectionTime(event)}
          >
            <MenuItem value={1}>Monthly</MenuItem>
            <MenuItem value={12}>Annually</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="centerDiv">
        <FormControlLabel
          control={
            <Checkbox
              checked={fixedCharge}
              onChange={(event) => handleSelectionFixedCharge(event)}
              color="primary"
            />
          }
          label="Fixed Charges included"
        ></FormControlLabel>
      </div>

      <div className="submitButton">
        <Button variant="contained" onClick={() => handleSubmit()}>
          Calculate
        </Button>
      </div>
      {submitState > 0 && (
        <div>
          <LoadingIcons.BallTriangle fill="#06bcee" className="centerme" />
        </div>
      )}
      {displayResult > 0 && (
        <div className="grid-container">
          <div className="grid-child">
            <div className="ResultBox">
              <h5>Total Number of Households Who Would Benefit</h5>
              <div className="result">
                {Intl.NumberFormat().format(Population[0])}
              </div>
            </div>
            <div className="ResultBox">
              <h5>Total Cost of Program</h5>
              <div className="result">
                ${Intl.NumberFormat().format(Result[0])}
              </div>
            </div>
          </div>
          <div className="grid-child">
            <div className="ResultBox">
              <h5>Average Bill Subsidy</h5>
              <div className="result">
                ${Intl.NumberFormat().format(AverageBenfit[0])}
              </div>
            </div>
            <div className="ResultBox">
              <h5>Average Total Cost per Participating Household</h5>
              <div className="result">
                ${Intl.NumberFormat().format(AverageCost[0])}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* PDF stored in public pdf folder */}

      <Link
        to="./pdf/NationwideEstimateDocumentation.pdf"
        target="_blank"
        download
        className="lastSection"
      >
        Download pdf of Documentation
      </Link>
      <div className="buffer"></div>
      <SEO url="estimation" />
    </SiteWrapper>
  );
}

export default EstimationForm;
