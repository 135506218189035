import React from "react";

import EstimationForm from "../components/EstmationForm";
import "../Main.css";

function Estimation() {
  return <EstimationForm />;
}

export default Estimation;
